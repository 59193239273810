import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Carousel = () => {
  const [gifs, setGifs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cameraNames, setCameraNames] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}snapshotdetails/`
      );
      const data = await response.json();
      console.log(data);
      console.log("size of gifs is " + data.length);
      const NUM_GIFS_IN_MEMORY = 30;
      setGifs(data.slice(0, NUM_GIFS_IN_MEMORY));
      await fetchCameraNames(data.slice(0, NUM_GIFS_IN_MEMORY));
    };
    fetchData();
  }, []);

    const fetchCameraNames = async (gifs) => {
      const names = {};
      for (const gif of gifs) {
        const camResponse = await fetch(gif.camera);
        const camData = await camResponse.json();
        names[gif.snapshotId] = camData.camName;
      }
      setCameraNames(names);
    };

  const handleNext = () => {
    const NUM_GIFS_IN_MEMORY = 30;
    setCurrentIndex((prevIndex) => (prevIndex === NUM_GIFS_IN_MEMORY ? 0 : prevIndex + 1));
  };

  const handlePrev = () => {
    const NUM_GIFS_IN_MEMORY = 30;
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? NUM_GIFS_IN_MEMORY : prevIndex - 1));
  };

  useEffect(() => {
    const intervalId = setInterval(handleNext, 5000);
    return () => clearInterval(intervalId);
  }, []);

  //const opts = {
  //  height: "600",
  //  width: "1100",
  //};

  const renderGifDetails = () => {
    if (gifs.length > 0) {
      const currentGif = gifs[currentIndex];
      const cameraName = cameraNames[currentGif.snapshotId] || "Loading...";

      const date = new Date(currentGif.capturedTime);

      // Separate options for date and time
      const dateOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
      };

      // Formatting date and time separately
      const formattedDate = date.toLocaleDateString("en-US", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

      // Concatenating date and time without "at"
      const timestamp = `${formattedDate}, ${formattedTime}`;

      return (
        <div className="absolute bottom-4 left-4 p-2 text-white">
          <div className="text-2xl font-bold">{cameraName}</div>
          <div className="text-xl">{timestamp}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="relative flex justify-center items-center w-full h-full">
      {gifs.length > 0 && (
        <img
          src={`${process.env.REACT_APP_BACKEND_GENERATEDSNAPS_AS_MEDIA}/${gifs[currentIndex].generatedGif}.gif`}
          className="h-full w-full object-cover"
        />
      )}
      {renderGifDetails()}
      <div className="absolute left-4 md:left-12 top-1/2 transform -translate-y-1/2">
        <button
          className="p-3 rounded-full bg-white text-slate-700 shadow-lg focus:outline-none"
          onClick={handlePrev}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="2x" />
        </button>
      </div>
      <div className="absolute right-4 md:right-12 top-1/2 transform -translate-y-1/2">
        <button
          className="p-3 rounded-full bg-white text-slate-700 shadow-lg focus:outline-none"
          onClick={handleNext}
        >
          <FontAwesomeIcon icon={faArrowRight} size="2x" />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
