import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
