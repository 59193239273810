import React, { useState } from "react";
import axios from "axios";
import { Modal } from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const BookUpload = () => {
  const [bookTitle, setBookTitle] = useState("");
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [link, setLink] = useState("");
  const [currentTime, setCurrentTime] = useState(dayjs()); // New state for temporary time selection

  // state variables for error messages
  const errorMsg = {
    bookTitle: "Please enter a title.",
    file: "Please upload the book file to start receiving paragraphs daily.",
    time: "Please select at least one time.",
  };
  const [bookTitleError, setBookTitleError] = useState(false);
  const [selectedFileError, setSelectedFileError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  // for modal box
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleBookSubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    setFormSubmitted(true);

    // Validate book title
    if (!bookTitle) {
      setBookTitleError(true);
      isValid = false;
    } else {
      setBookTitleError(false);
    }

    // Validate file
    if (!selectedFile) {
      setSelectedFileError(true);
      isValid = false;
    } else {
      setSelectedFileError(false);
    }

    // Validate time selection
    if (selectedTimes.length === 0) {
      setTimeError(true);
      isValid = false;
    } else {
      setTimeError(false);
    }

    if (isValid) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      console.log(selectedTimes);
      formData.append(
        "timesOfDay",
        JSON.stringify(selectedTimes.map((time) => time))
      );
      formData.append("title", bookTitle);

      axios
        .post(process.env.REACT_APP_BACKEND_URL + "bookupload/", formData, {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=" + formData._boundary,
          },
        })
        .then((response) => {
          console.log("success");
          setResponseMessage(response.data.text);
          openModal();

          // Reset form submission state
          setBookTitle("");
          setSelectedTimes([]);
          setSelectedFile(null);
          setLink("");
          setCurrentTime(null);
          setFormSubmitted(false);
        })
        .catch((error) => {
          console.log(error);
        });

      setLink("");
    }
  };

  const handleLinkSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}fetchdata/`, {
        key: link,
        timesOfDay: selectedTimes.map((time) => time.format("HH:mm")),
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileInputChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const addSelectedTime = () => {
    const formattedTime = currentTime.format("HH:mm");
    if (!selectedTimes.includes(formattedTime)) {
      setSelectedTimes([...selectedTimes, formattedTime]);
      setTimeError(false);
    } else {
      setTimeError("This time is already selected.");
    }
  };

  const removeTime = (index) => {
    setSelectedTimes(selectedTimes.filter((_, i) => i !== index));
  };

  return (
    <div>
      <form
        className="w-full max-w-lg mx-auto my-16"
        onSubmit={(e) => {
          e.preventDefault();
          handleBookSubmit(e);
        }}
      >
        {/* Book Title Input */}
        <div className="mb-6">
          <label
            className="block text-gray-700 text-lg font-semibold mb-2"
            htmlFor="book-title"
          >
            Book Title
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="book-title"
            type="text"
            placeholder="Enter book title"
            value={bookTitle}
            onChange={(e) => setBookTitle(e.target.value)}
          />
          {formSubmitted && bookTitleError && (
            <p className="text-red-500 text-sm mt-2">{errorMsg.bookTitle}</p>
          )}
        </div>

        {/* MUI MobileTimePicker */}
        <div className="mb-6">
          <label className="block text-gray-700 text-lg font-semibold mb-2">
            Paragraph Dispatch Times
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="flex items-center gap-4">
              <MobileTimePicker
                value={currentTime}
                onChange={setCurrentTime}
                renderInput={(params) => (
                  <input
                    className="inputField py-2 px-4 rounded border border-gray-300 focus:outline-none focus:border-blue-500"
                    {...params}
                  />
                )}
              />
              <button
                type="button"
                onClick={addSelectedTime}
                className="addTimeButton bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-200"
              >
                Add Time
              </button>
            </div>
          </LocalizationProvider>
          {/* Display Selected Times as Tags/Chips */}
          <div className="mt-3 flex flex-wrap gap-3">
            {selectedTimes.map((time, index) => (
              <div
                key={index}
                className="timeTag bg-gray-200 rounded-full px-4 py-1 flex items-center gap-3"
              >
                {time}
                <button
                  type="button"
                  onClick={() => removeTime(index)}
                  className="removeTimeButton text-gray-600 hover:text-red-500"
                >
                  &#10006;
                </button>
              </div>
            ))}
          </div>
          {formSubmitted && timeError && (
            <p className="errorText text-red-500 text-sm mt-2">{timeError}</p>
          )}
        </div>

        {/* File Input */}
        <div className="mb-6">
          <label
            className="block text-gray-700 text-lg font-semibold mb-2"
            htmlFor="file-input"
          >
            Attach Book File (PDF, DOC, DOCX)
          </label>
          <input
            className="appearance-none bg-white border border-gray-400 hover:border-gray-500 px-4 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            id="file-input"
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={handleFileInputChange}
          />
          {formSubmitted && selectedFileError && (
            <p className="text-red-500 text-sm mt-2">{errorMsg.file}</p>
          )}
        </div>

        {/* Submit Button */}
        <div className=" flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-200"
            type="submit"
          >
            Upload Book
          </button>
        </div>
      </form>

      <form className="w-full max-w-lg mx-auto" onSubmit={handleLinkSubmit}>
        <div className="mb-4">
          <label htmlFor="link" className="block text-gray-700 font-bold mb-2">
            Link
          </label>
          <input
            id="link"
            type="text"
            placeholder="Enter link here"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="time-picker"
          >
            Time
          </label>
          {/* <TimePicker
            id="time-picker"
            className="w-full appearance-none bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            showSecond={false}
            defaultValue={currentTime}
            onChange={setCurrentTime}
            allowEmpty={false}
          /> */}
        </div>

        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Upload Link
          </button>
        </div>
      </form>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="fixed z-50 inset-0 overflow-y-auto"
          onClick={closeModal}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <button
                className="absolute right-0 top-0 mt-2 mr-2"
                onClick={closeModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-700 hover:text-gray-900 transition-colors duration-200"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <h2 className="text-xl font-bold mb-4">
                Book Upload Confirmation
              </h2>
              <p id="modal-modal-description" className="text-gray-700">
                {responseMessage}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BookUpload;
